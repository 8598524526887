import React, { useEffect, useState } from 'react';
import './App.css';
import io from 'socket.io-client';
import { Typography, Button, TextField, Fab, Stack, Box } from '@mui/material';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const url = process.env.WEBSOCKET_URL || "/";
const socket = io(url, { transports: ['websocket'] });

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataFromQueryString = queryParams.get('data');
  const [dataText, setDataText] = useState(dataFromQueryString || '');
  const [promptText, setPromptText] = useState('');
  const [systemText, setSystemText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [qrData, setQrData] = useState('');

  const [isGenerating, setIsGenerating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleGetData = () => socket.emit('get-data', { prompt: promptText, system: systemText });
  socket.on('data', (data) => {  setDataText(data.data); } );
  useEffect(() => {
    const encodedDataText = encodeURIComponent(dataText);
    window.history.replaceState(null, '', `?data=${encodedDataText}`);
    socket.emit('get-qrcode', { data: dataText });
  }, [dataText]);
  socket.on('qrdata', (data) => {  setQrData("data:image/png;base64, " + data.data); });

  const handleGetMessage = () => { setResponseText(""); setIsGenerating(true); socket.emit('get-message', { data: dataText } ); }
  socket.on('response', (data) => { setResponseText(responseText + data.data); });

  const handleStop = () => { setIsGenerating(false); socket.emit('stop');}

  return ( 
    <Box className="App">
      {isCreating && <Stack spacing={2} direction="row">
          <Stack spacing={2} direction="column">
            <TextField
              fullWidth
              multiline
              minRows={10}
              label="Prompt Text"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}              
            />
            <TextField
              fullWidth
              multiline
              minRows={10}
              label="System Text"
              value={systemText}
              onChange={(e) => setSystemText(e.target.value)}
            />
          </Stack>
          <Stack spacing={2} direction="column">
            <Button variant="contained" color="primary" onClick={handleGetData}>
              Get Data
            </Button>
            {qrData && <img src={qrData} width={300} />}
          </Stack>
        </Stack>
      }
      {!isCreating && <>
        {!isGenerating && dataText && <Button variant="contained" color="primary" onClick={handleGetMessage}>
          Get Message
        </Button>}
        {isGenerating && <>
          <Typography>{responseText.replaceAll("\r", "<br/>")}</Typography>
          <Button variant="contained" color="primary" onClick={handleStop}>
            Stop
          </Button>
        </>}
      </>}
      <Fab size="small" color="secondary" aria-label="add" onClick={()=> setIsCreating(!isCreating)} >
        <AddIcon />
      </Fab>
    </Box>
  );
}

const AppRouterWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>      
    </BrowserRouter>
  );
}

export default AppRouterWrapper;